<template>
  <v-row
    :class="
      this.$vuetify.breakpoint.name == 'xs' ||
      this.$vuetify.breakpoint.name == 'sm'
        ? 'px-0'
        : 'px-2'
    "
  >
    <v-col cols="12" class="pa-0">
      <v-breadcrumbs
        class="pl-0 primary--text"
        :items="navigator"
        divider="/"
      ></v-breadcrumbs>
    </v-col>
    <v-col class="pb-1" cols="12">
      <strong class="primary--text devTitleType">Escape Unescape</strong>
    </v-col>

    <v-col cols="12">
      <v-card min-height="100%" height="max-content" max-height="60vh">
        <v-card-title>Input Text</v-card-title>
        <v-card-text>
          <v-textarea
            background-color="#111111"
            v-model="AlterText"
            rows="10"
            outlined
            placeholder="Enter text here ...."
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="buttonPosition">
      <v-btn
        :small="this.$vuetify.breakpoint.name == 'xs' ? true : false"
        outlined
        color="primary"
        class="btnPad px-2"
        @click="escapeText"
        >Escape</v-btn
      >
      <v-btn
        :small="this.$vuetify.breakpoint.name == 'xs' ? true : false"
        outlined
        color="primary"
        class="btnPad"
        @click="unescapeText"
        >Unescape</v-btn
      >

      <v-btn
        :small="this.$vuetify.breakpoint.name == 'xs' ? true : false"
        outlined
        color="primary"
        class="btnPad"
        @click="clearText"
        >Reset</v-btn
      >
    </v-col>
    <v-col cols="12">
      <v-card min-height="100%" height="max-content" max-height="70vh">
        <v-card-title>Output</v-card-title>
        <v-card-text>
          <v-textarea
            background-color="#111111"
            v-model="AlteredText"
            rows="10"
            ref="output"
            outlined
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card tile>
        <v-card-title class="primary--text devTitleType"
          >What is Escape/Unescape ?</v-card-title
        >
        <v-card-text class="devTextType"
          >The tool <strong class="primary--text">"escapes"</strong> or
          <strong class="primary--text">"unescapes"</strong> a JavaScript
          string, which means it modifies the string to remove characters that
          could cause issues or confusion when the string is used in JavaScript
          code. This helps ensure that the string is interpreted correctly
          without any problems. <br /><br />

          <strong class="mt-3 ml-3"
            >The following characters are reserved in JavaScript and must be
            properly escaped to be used in strings:</strong
          >
          <ul class="ma-2">
            <li class="my-2">
              <strong class="primary--text">Horizontal Tab</strong>
              is replaced with <strong class="primary--text">\t</strong>
            </li>
            <li class="my-2">
              <strong class="primary--text">Vertical Tab</strong>
              is replaced with <strong class="primary--text">\v</strong>
            </li>
            <li class="my-2">
              <strong class="primary--text">Nul char</strong>
              is replaced with <strong class="primary--text">\0</strong>
            </li>
            <li class="my-2">
              <strong class="primary--text">Backspace </strong>
              is replaced with <strong class="primary--text">\b</strong>
            </li>
            <li class="my-2">
              <strong class="primary--text">Form feed</strong>
              is replaced with <strong class="primary--text">\f</strong>
            </li>
            <li class="my-2">
              <strong class="primary--text">Newline </strong>
              is replaced with <strong class="primary--text">\n</strong>
            </li>
            <li class="my-2">
              <strong class="primary--text">Carriage return</strong>
              is replaced with <strong class="primary--text">\r</strong>
            </li>
            <li class="my-2">
              <strong class="primary--text">Single quote</strong>
              is replaced with <strong class="primary--text">\'</strong>
            </li>
            <li class="my-2">
              <strong class="primary--text">Double quote</strong>
              is replaced with <strong class="primary--text">\"</strong>
            </li>
            <li class="my-2">
              <strong class="primary--text">Backslash</strong>
              is replaced with <strong class="primary--text">\\</strong>
            </li>
            <li class="my-2">
              <strong class="primary--text">Double Backslash</strong>
              is replaced with <strong class="primary--text">\\\</strong>
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { scrollToTop } from "@/helpers/helperFunctions.js";

export default {
  data() {
    return {
      navigator: [
        {
          text: "Developer Tools",
          disabled: false,
          href: "",
        },
        {
          text: "Formatter",
          disabled: false,
          href: "",
        },
        {
          text: "Escape Unescape",
          disabled: true,
          href: "escape-unescape",
        },
      ],
      AlterText: "",
      AlteredText: "",
    };
  },
  methods: {
    escapeString(str) {
      return str.replace(/[\\'"\n\r\t\b\f\v]/g, function (char) {
        switch (char) {
          case "\\":
            return "\\\\";
          case "'":
            return "\\'";
          case '"':
            return '\\"';
          case "\n":
            return "\\n";
          case "\r":
            return "\\r";
          case "\t":
            return "\\t";
          case "\b":
            return "\\b";
          case "\f":
            return "\\f";
          case "\v":
            return "\\v";
        }
      });
    },
    unescapeString(str) {
      return str.replace(/\\([\\'"\n\r\t\b\f\v])/g, function (_, escapedChar) {
        switch (escapedChar) {
          case "\\":
            return "\\";
          case "'":
            return "'";
          case '"':
            return '"';
          case "n":
            return "\n";
          case "r":
            return "\r";
          case "t":
            return "\t";
          case "b":
            return "\b";
          case "f":
            return "\f";
          case "v":
            return "\v";
        }
      });
    },

    escapeText() {
      this.AlteredText = this.escapeString(this.AlterText);
      this.scrollOutputContainer();
    },
    unescapeText() {
      this.AlteredText = this.unescapeString(this.AlterText);
      this.scrollOutputContainer();
    },
    clearText() {
      this.AlterText = "";
      this.AlteredText = "";
    },
    scrollOutputContainer() {
      this.$nextTick(() => {
        const outputElement = this.$refs.output;
        outputElement.$el.scrollIntoView({ behavior: "smooth" });
      });
    },
  },
  mounted() {
    scrollToTop();
  },
};
</script>
<style scopped>
.buttonPosition {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.btnPad {
  margin-bottom: 20px;
}

@media screen and (max-width: 1260px) {
  .buttonPosition {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .btnPad {
    margin-right: 20px;
  }
}
</style>
